import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Seo from '../components/Seo'
import { useAppContext } from '../components/AppContext'

const NotFoundPage = () => {
  const { language } = useAppContext()

  const NotFoundPageQuery = graphql`
    query notFoundPage {
      ru: markdownRemark(fields: { slug: { eq: "/docs/ru/404/" } }) {
        html
        frontmatter {
          title
          description
        }
      }
      en: markdownRemark(fields: { slug: { eq: "/docs/en/404/" } }) {
        html
        frontmatter {
          title
          description
        }
      }
      pt: markdownRemark(fields: { slug: { eq: "/docs/pt/404/" } }) {
        html
        frontmatter {
          title
          description
        }
      }
      zh: markdownRemark(fields: { slug: { eq: "/docs/zh/404/" } }) {
        html
        frontmatter {
          title
          description
        }
      }
    }
  `
  const notFoundPage = useStaticQuery(NotFoundPageQuery)

  return (
    <>
      <Seo title="404: Not Found" />
      <div dangerouslySetInnerHTML={{ __html: notFoundPage[language]?.html }} />
    </>
  )
}

export default NotFoundPage
